import axios from 'axios';

const API = 'propietarios';

const ENDPOINTS = {

    getListaPropietarios(params){
        return axios.get(`${API}/lista`, params)
    },
    getInfoPropietario(idPropietario){
        return axios.get(`${API}/${idPropietario}/ver`)
    },
    postPropietario(payload){
        return axios.post(`${API}/crear`, payload)
    },
    editInfoPropietario(payload){
        return axios.put(`viviendas/residente/propietario/editar`, payload)
    },
    deletePropietario(idPropietario){
        return axios.delete(`${API}/${idPropietario}/delete`)
    },
    exportarExcel(){
        return axios.get(`${API}/exportar`)
    },

};

export default ENDPOINTS;